import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService, ExistingConverter } from 'ssotool-app/+client';
import { Coerce, mapEntitiesToSelectOptions } from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ExistingConverterDrawerComponent,
  ExistingConverterDrawerData,
  ExistingConverterDrawerMode,
} from 'ssotool-app/+entities/components/drawer';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

@Component({
  selector: 'sso-existing-converter-list',
  templateUrl: './existing-converter-list.component.html',
  styleUrls: ['./existing-converter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExistingConverterListComponent implements OnInit {
  /** Template variables */
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'geography', label: 'Site/Geography' },
    { name: 'process' },
    { name: 'input', mode: 'array' },
    { name: 'output', mode: 'array' },
  ];
  fluidOptions$ = new BehaviorSubject<any>([]);

  constructor(
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.clientFacade
          .selectFluids$(client.clientId)
          .pipe(
            skipWhile((data) => !data),
            map((data) => mapEntitiesToSelectOptions(data)),
          )
          .subscribe((data) => this.fluidOptions$.next(data));
        this.entities$ = this.clientFacade
          .selectConverters$(client.clientId)
          .pipe(
            skipWhile((data) => !data),
            map((data) =>
              Coerce.getObjValues(data).map((converter) => ({
                ...converter,
                name: converter.name,
                geography: converter.geoName || converter.siteName,
                entity: converter.companyName,
                sector: converter.sectorName,
                process: converter.processName,
                input: converter.consumptionFluids,
                output: converter.productionFluids,
              })),
            ),
          );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  openDrawer(existingConverter: ExistingConverter) {
    this.drawerService.open<
      ExistingConverterDrawerComponent,
      ExistingConverterDrawerData,
      unknown
    >(ExistingConverterDrawerComponent, {
      data: {
        mode: ExistingConverterDrawerMode.VIEW,
        clientId: this.clientId,
        existingConverter,
        readonly: true,
      },
      width: '50vw',
      closeIcon: false,
      overrideClose: true,
    });
  }
}
