import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';
import { CampaignFacadeService } from 'ssotool-app/+campaign/store';
import { BreadcrumbLevel } from 'ssotool-app/shared/component/breadcrumb/breadcrumb.model';

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OCULUS_DETAILS_DRAWER_DATA } from '@oculus/components/details-drawer';

import { DrawerDisplayMode } from './base-drawer.model';
import { CRUMB_PRODUCTION_PROFILES } from './base-drawer.references';
import { CampaignDrawerData, CampaignFormModel } from './campaign-drawer';
import { ProfileDetails } from 'ssotool-app/shared/modules/profile-input/profile-input.model';
import { TitleBarBreadcrumb } from 'ssotool-app/shared/modules/title-bar/title-bar.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-base-drawer',
  templateUrl: './base-drawer.component.html',
  styleUrls: ['./base-drawer.component.scss'],
})
export class BaseDrawerComponent implements OnInit {
  private _data = new BehaviorSubject<CampaignDrawerData>(null);
  private _displayMode = new BehaviorSubject<DrawerDisplayMode>(
    DrawerDisplayMode.CAMPAIGN,
  );

  baseControl = new FormGroup({
    profilesFormControl: new FormControl<ProfileDetails>(null),
    campaignFormControl: new FormControl<CampaignFormModel>(null),
  });
  displayMode$ = this._displayMode.asObservable();

  breadcrumbLevels$: Observable<BreadcrumbLevel<string>[]> =
    this._displayMode.pipe(
      map((mode) => {
        return [
          {
            name: this.campaign?.name,
            value: DrawerDisplayMode.CAMPAIGN,
          },
          ...(mode === DrawerDisplayMode.PROFILES
            ? [CRUMB_PRODUCTION_PROFILES]
            : []),
        ];
      }),
    );

  campaignLoading$ = this.campaignData?.campaign?.id
    ? this.campaignFacade.campaignLoading$(this.campaignData?.campaign?.id)
    : this.campaignFacade.loading$;

  displayModeExecutor: Record<DrawerDisplayMode, () => {}> = {
    [DrawerDisplayMode.CAMPAIGN]: this.setCampaignDisplayMode.bind(this),
    [DrawerDisplayMode.PROFILES]: this.setProfilesDisplayMode.bind(this),
  };

  get data() {
    return this._data?.value;
  }
  get displayMode() {
    return this._displayMode.value;
  }

  get campaign() {
    return this.campaignData?.campaign;
  }

  get campaignFormControl() {
    return this.baseControl.controls.campaignFormControl;
  }

  get profilesFormControl() {
    return this.baseControl.controls.profilesFormControl;
  }

  get mode() {
    return this.campaignData?.mode;
  }

  constructor(
    @Inject(OCULUS_DETAILS_DRAWER_DATA)
    private campaignData: CampaignDrawerData,
    private campaignFacade: CampaignFacadeService,
  ) {}

  ngOnInit(): void {
    this._data.next(this.campaignData);
    this.initializeCampaignControlSubscription();
  }

  handleCrumbNavigation(crumb: BreadcrumbLevel<string>): void {
    this.displayModeExecutor[crumb.value]();
  }

  onBreadcrumbNavigate(crumb: TitleBarBreadcrumb): void {
    this.displayModeExecutor[crumb.id]();
  }

  private initializeCampaignControlSubscription(): void {
    this.campaignFormControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilKeyChanged('campaignTypeForm'))
      .subscribe(({ campaignTypeForm }: CampaignFormModel) => {
        if (campaignTypeForm?.selectedSiteControl) {
          this.setProfilesDisplayMode();
          const profileDetails: ProfileDetails = {
            campaignName: campaignTypeForm.name,
            campaignId: campaignTypeForm.id,
            companyId: campaignTypeForm.companyId || null,
            geoId: campaignTypeForm.geoId,
            siteId: campaignTypeForm.siteId,
            clientId: campaignTypeForm.clientId,
            isGeoGroup: campaignTypeForm.isGeoGroup,
            selectedSite: campaignTypeForm.selectedSiteControl,
          };
          this.profilesFormControl.patchValue(profileDetails);
        }
      });
  }

  private setProfilesDisplayMode(): void {
    this._displayMode.next(DrawerDisplayMode.PROFILES);
  }

  private setCampaignDisplayMode(): void {
    this._displayMode.next(DrawerDisplayMode.CAMPAIGN);
  }
}
