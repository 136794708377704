import {
  BehaviorSubject,
  combineLatest,
  MonoTypeOperatorFunction,
  Subject,
  Subscription,
} from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { CampaignBaseModel } from 'ssotool-app/+campaign/store';
import {
  ClientFilterFunctionDatum,
  ClientFiltersService,
} from 'ssotool-app/+client';
import { CAMPAIGN_COLORS, REFERENCES } from 'ssotool-app/app.references';
import { fadeInAnimation } from 'ssotool-core/animations';
import {
  FilteredComponent,
  FilterWithCondition,
  GROUP_INDICATOR,
} from 'ssotool-shared/modules';

import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import {
  COLUMNS,
  FILTER_COLUMNS,
  READONLY_COLUMNS,
} from './campaign-table.references';

@Component({
  selector: 'sso-campaign-table',
  styleUrls: ['campaign-table.component.scss'],
  templateUrl: 'campaign-table.component.html',
  animations: [fadeInAnimation],
})
export class CampaignTableComponent
  extends FilteredComponent
  implements OnInit, OnDestroy
{
  COLOR = CAMPAIGN_COLORS;
  REFS = REFERENCES;
  displayedColumns = READONLY_COLUMNS;
  filters = FILTER_COLUMNS;
  stripLabel = GROUP_INDICATOR;

  form = this.formBuilder.group({
    filters: [],
    searchKey: '',
  });
  chipForm = new FormControl({});

  selection = new SelectionModel<CampaignBaseModel>(true, []);

  private _clientId = new BehaviorSubject<string>('');
  @Input() set clientId(clientId: string) {
    this._clientId.next(clientId);
  }
  get clientId() {
    return this._clientId.value;
  }

  @Input() activeFilters: string[] = [];

  private _data = new BehaviorSubject<CampaignBaseModel[]>([]);
  @Input() set data(d: CampaignBaseModel[]) {
    if (d) {
      const _d = d.map((_ds) => ({
        ..._ds,
        type: this.REFS[_ds.campaignType],
      }));

      this._data.next(_d);
      this.filteredCampaigns.next(_d);
      this.selection = new SelectionModel<CampaignBaseModel>(true, []);
    }
  }
  get data() {
    return this._data.value;
  }

  @Input() set clearSelection(value: boolean) {
    if (value && this.selection) {
      this.selection.clear();
    }
  }

  private filteredCampaigns = new BehaviorSubject<CampaignBaseModel[]>([]);
  filterOptions$ = this._clientId.pipe(
    mergeMap((id) =>
      this.filtersService.getFilterOptions(id, [
        {
          obs: this._data,
          attributes: this.filters,
          labelMapper: {},
        },
      ]),
    ),
  );
  filtersDialogConfig = this.filtersService.dialogConfig;

  datasource$ = combineLatest([
    this.filteredCampaigns,
    this.searchControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([campaigns, searchKey]: [CampaignBaseModel[], string]) => {
      const datasource = new MatTableDataSource<CampaignBaseModel>(campaigns);
      datasource.paginator = this.paginator;
      datasource.sort = this.sort;
      datasource.filter = searchKey.trim().toLocaleLowerCase();

      return datasource;
    }),
  );

  noData$ = this.datasource$.pipe(map((source) => source.data.length <= 0));
  isAllSelected$ = this.datasource$.pipe(
    map((source) => source.data.length === this.selection.selected.length),
  );
  isNotAllSelected$ = this.isAllSelected$.pipe(map((isAll) => !isAll));

  @Input() isPermitted: boolean;
  @Input() loading = true;
  @Input() hasOngoingExport = false;
  @Input() hasOngoingImport = false;
  @Input() ineligibleCampaigns = [];
  @Output() delete = new EventEmitter();
  @Output() deleteMulti = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() populate = new EventEmitter();
  @Output() duplicate = new EventEmitter();
  @Output() batchDuplicate = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() openImport = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get searchControl() {
    return this.form.controls.searchKey as FormControl;
  }

  get filterForm() {
    return this.form.controls.filters as FormControl;
  }

  get columnDefinitions(): string[] {
    return this.isPermitted
      ? COLUMNS.map((c) => c.name)
      : READONLY_COLUMNS.map((c) => c.name);
  }

  private subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private filtersService: ClientFiltersService,
  ) {
    super(filtersService);
  }

  ngOnInit() {
    this.initializeForm();
    this.initializeFiltersAndLevel();
    this.initializeToggleEvent();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeForm() {
    this.form.patchValue({
      filters: this.activeFilters.reduce((acc, curr) => {
        acc[curr] = [];
        return acc;
      }, []),
      searchKey: '',
    });
  }

  private initializeToggleEvent() {
    this.toggleAllSelection
      .pipe(
        mergeMap(() =>
          combineLatest([this.datasource$, this.isAllSelected$]).pipe(
            tap(([source, isAllSelected]) =>
              isAllSelected
                ? this.selection.clear()
                : this.selection.select(...source.data),
            ),
          ),
        ),
      )
      .subscribe();
  }

  protected initializeFiltersAndLevel(): void {
    this.subscriptions.add(
      this.filtersService.initializeFilterControls(
        this.filterForm,
        [],
        [this.chipForm],
        this.filterControlFunction.bind(this),
      ),
    );
  }

  protected filterControlFunction?(
    filters: FilterWithCondition,
    level?: string,
  ) {
    return this.filtersService
      .getFilterControlFunction(this.clientId, this.data, filters)
      .pipe(this.updateFilteredCampaigns());
  }

  private updateFilteredCampaigns(): MonoTypeOperatorFunction<
    ClientFilterFunctionDatum<CampaignBaseModel>
  > {
    return tap(({ data }) => {
      this.filteredCampaigns.next(data);
    });
  }

  addCampaign() {
    this.add.emit('ADD');
  }

  populateWithLibrary() {
    this.populate.emit('POPULATE');
  }

  private toggleAllSelection = new Subject();

  masterToggle() {
    this.toggleAllSelection.next(null);
  }

  deleteSelection(): void {
    if (this.selection.selected.length === 1) {
      this.delete.emit(this.selection.selected[0]);
    } else {
      this.deleteMulti.emit(this.selection.selected);
    }
  }

  duplicateSelection(): void {
    if (this.selection.selected.length === 1) {
      this.duplicate.emit(this.selection?.selected[0]);
    } else {
      this.batchDuplicate.emit(this.selection?.selected);
    }
  }

  exportSelection(): void {
    this.export.emit(this.selection?.selected);
  }

  isSelected(): boolean {
    return this.selection?.isEmpty();
  }

  openImportList(): void {
    this.openImport.emit();
  }

  isEligibleWarningHidden(data: any): boolean {
    return !this.ineligibleCampaigns?.includes(data?.id);
  }

  onRowClick(data) {
    if (!this.loading) {
      this.rowClick.emit(data);
    }
  }
}
