export const READONLY_COLUMNS = [
  { name: 'name', label: 'Name' },
  { name: 'type', label: 'Type' },
  { name: 'pathway', label: 'Pathway' },
  { name: 'geography', label: 'Site / Geography' },
];

export const FILTER_COLUMNS = ['name', 'type', 'pathway'];

export const COLUMNS = [{ name: 'select', label: '' }].concat(READONLY_COLUMNS);
